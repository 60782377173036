var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-card',{attrs:{"title":_vm.$t('table.useraccount.tab1.title1')}},[_c('a-table',{staticStyle:{"font-size":"12px"},attrs:{"columns":_vm.columns,"size":"small","rowKey":(record, index) => index,"dataSource":_vm.dataSource,"pagination":false,"bordered":""},scopedSlots:_vm._u([{key:"action",fn:function(obj){return _c('span',{},[_c('a-button',{attrs:{"disabled":obj.status != '0'},on:{"click":function($event){return _vm.delFile(obj.fileId)}}},[_vm._v(_vm._s(_vm.$t('table.useraccount.file.cloums.del')))])],1)}}])})],1),(_vm.totalResults.length > 0)?_c('a-card',{attrs:{"title":_vm.$t('table.useraccount.tab1.title2')}},[_c('a-table',{staticStyle:{"font-size":"12px"},attrs:{"columns":_vm.columns2,"size":"small","rowKey":(record, index) => index,"dataSource":_vm.totalResults,"pagination":false,"scroll":{
				x: true
			},"bordered":""},scopedSlots:_vm._u([{key:"action",fn:function(obj){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.showData(obj.incorrectList)}}},[_vm._v(_vm._s(obj.incorrectBalance))])])}}],null,false,1265929110)})],1):_vm._e(),(_vm.mpesalist.length > 0)?_c('a-card',{attrs:{"title":"M-pesa"}},[_c('a-table',{staticStyle:{"font-size":"12px"},attrs:{"columns":_vm.columns3,"size":"small","rowKey":(record, index) => index,"dataSource":_vm.mpesalist,"pagination":false,"scroll":{
				x: true
			},"bordered":""},scopedSlots:_vm._u([{key:"action",fn:function(obj){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.showData(obj.incorrectList)}}},[_vm._v(_vm._s(obj.incorrectBalance))])])}}],null,false,1265929110)})],1):_vm._e(),_vm._l((_vm.bankResults),function(item){return _c('a-card',{key:item.bankName,attrs:{"title":item.bankName}},[_c('a-table',{staticStyle:{"font-size":"12px"},attrs:{"columns":_vm.columns2,"size":"small","rowKey":(record, index) => index,"dataSource":item.flowList,"pagination":false,"bordered":"","scroll":{
				x: true
			}}})],1)}),_c('a-modal',{directives:[{name:"drag-modal",rawName:"v-drag-modal"}],attrs:{"title":_vm.modal.title,"width":"70%","footer":null},model:{value:(_vm.modal.show),callback:function ($$v) {_vm.$set(_vm.modal, "show", $$v)},expression:"modal.show"}},[_c('a-table',{staticStyle:{"font-size":"12px"},attrs:{"columns":_vm.modal.cloums,"size":"small","rowKey":(record, index) => index,"dataSource":_vm.modal.dataSource,"pagination":_vm.modal.pagination,"bordered":"","scroll":{
				x: true,
				y: 600
			}},on:{"change":_vm.handleTableChange}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }