import { _axios } from '@/plugins/axios'

// 查看记录列表
export const apiGetClientList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/list`,
		method: method,
		data: dataJson
	})
}

// 刷新用户状态
export const apiPatchClient = (dataJson, method = 'patch') => {
	return _axios({
		url: `/car/flowUser`,
		method: method,
		data: dataJson
	})
}
// 用户作废
export const apiDelClient = (dataJson, method = 'delete') => {
	return _axios({
		url: `/car/flowUser`,
		method: method,
		data: dataJson
	})
}

// 用户提交信审
export const apiClientCredit = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/flowUser/credit`,
		method: method,
		data: dataJson
	})
}

// 查看用户详细情况
export const apiClientDetail = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/detail`,
		method: method,
		data: dataJson
	})
}

// 添加用户流水
export const apiAddFile = (data) => {
	_axios.headers.post['Content-Type'] = 'multipart/form-data'
	return _axios({
		url: `/car/other/addFile`,
		method: 'post',
		data: data
	})
}

// 删除一个文件
export const apiDelFile = (dataJson, method = 'delete') => {
	return _axios({
		url: `/car/file`,
		method: method,
		data: dataJson
	})
}

// 添加一条记录
export const apiCreateClient = (data) => {
	_axios.headers.post['Content-Type'] = 'multipart/form-data'
	return _axios({
		url: `/car/other/createClient`,
		method: 'post',
		data: data
	})
}

// 查看用户收支交易类型
export const apiGetFlowDetail = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/flowDetail`,
		method,
		data: dataJson
	})
}

// 手工匹配用户收支交易类型
export const apiGetManualDetail = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/manualDetail`,
		method,
		data: dataJson
	})
}

// MPESA流水解析-收入
export const apiFlowIncomeDetail = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/flowIncomeDetail?id=${dataJson}`,
		method
	})
}

// MPESA流水解析-支出
export const apiFlowExpenseDetail = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/flowExpenseDetail?id=${dataJson}`,
		method
	})
}

// MPESA流水解析-盈余
export const apiFlowBalanceDetail = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/flowBalanceDetail?id=${dataJson}`,
		method
	})
}

// MPESA流水解析-贷款还款情况
export const apiFlowLoanAndRepaymentDetail = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/flowLoanAndRepaymentDetail?id=${dataJson}`,
		method
	})
}

// 申请资料解析接口
// 查看用户收支交易类型 .id}&type=${dataJson.type
export const apiGetFlowDetailByFileNames = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/flowDetailByFileNames?fileNames=${dataJson}`,
		method
	})
}

// 手工匹配用户收支交易类型
export const apiGetManualDetailByFileNames = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/manualDetailByFileNames?fileNames=${dataJson.fileNames}&keywords=${dataJson.keywords}`,
		method
	})
}

// MPESA流水解析-收入
export const apiFlowIncomeDetailByFileNames = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/flowIncomeDetailByFileNames?fileNames=${dataJson}`,
		method
	})
}

// MPESA流水解析-支出
export const apiFlowExpenseDetailByFileNames = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/flowExpenseDetailByFileNames?fileNames=${dataJson}`,
		method
	})
}

// MPESA流水解析-盈余
export const apiFlowBalanceDetailByFileNames = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/flowBalanceDetailByFileNames?fileNames=${dataJson}`,
		method
	})
}

// MPESA流水解析-贷款还款情况
export const apiFlowLoanAndRepaymentDetailByFileNames = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/flowLoanAndRepaymentDetailByFileNames?fileNames=${dataJson}`,
		method
	})
}
