<template>
	<a-spin :spinning="pageLoading">
		<a-card :title="$t('table.useraccount.tab2.title1')">
			<a-row>
				<a-form layout="inline" :form="tableForm">
					<a-form-item :label="$t('table.useraccount.tab2.flowline')">
						<a-select v-model="tableForm.type" style="min-width: 150px">
							<a-select-option value="ALL">
								{{ $t('table.all') }}
							</a-select-option>
							<a-select-option value="MPESA"> M-PESA </a-select-option>
							<a-select-option :value="item.codeValue" v-for="item in bankCode.banklist" :key="item.id">
								{{ item.codeDescription }}
							</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item>
						<a-button type="primary" @click="init" v-t.preserve="'operate.search'"></a-button>
					</a-form-item>
				</a-form>
			</a-row>
			<a-table
				style="font-size: 12px"
				:columns="columns1"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="dataSource1"
				:pagination="false"
				bordered
			>
				<span slot="action" slot-scope="obj">
					<a @click="showData(obj.details)">{{ $t('page.checkDetail') }}</a>
				</span>
			</a-table>
		</a-card>
		<a-card :title="$t('table.useraccount.tab2.title2')">
			<a-table
				style="font-size: 12px"
				:columns="columns2"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="dataSource2"
				:pagination="false"
				bordered
			>
				<span slot="action" slot-scope="obj">
					<a @click="showData(obj.details)">{{ $t('page.checkDetail') }}</a>
				</span>
			</a-table>
		</a-card>
		<a-card :title="$t('table.useraccount.tab2.title3')">
			<a-row>
				<a-form layout="inline" :form="tableForm2">
					<a-form-item :label="$t('table.useraccount.tab2.keyEnter')">
						<a-input v-model="tableForm2.keywords" :placeholder="$t('table.useraccount.tab2.placeholder')"></a-input>
					</a-form-item>
					<a-form-item>
						<a-button type="primary" @click="SearchKey" v-t.preserve="'operate.search2'"></a-button>
					</a-form-item>
				</a-form>
			</a-row>
			<a-table
				style="font-size: 12px"
				:columns="columns3"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="dataSource3"
				:pagination="false"
				bordered
			>
				<span slot="action" slot-scope="obj">
					<a @click="showData(obj.details)">{{ $t('page.checkDetail') }}</a>
				</span>
			</a-table>
		</a-card>

		<!-- 弹窗 -->
		<a-modal v-drag-modal v-model="modal.show" :title="modal.title" width="70%" :footer="null">
			<a-table
				style="font-size: 12px"
				:columns="modal.cloums"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="modal.dataSource"
				:pagination="modal.pagination"
				@change="handleTableChange"
				bordered
				:scroll="{
					x: true,
					y: 600
				}"
			>
			</a-table>
		</a-modal>
	</a-spin>
</template>
<script>
import { mapGetters } from 'vuex'
import { apiGetFlowDetail, apiGetManualDetail, apiGetFlowDetailByFileNames, apiGetManualDetailByFileNames } from '@/api/client'
export default {
	data() {
		return {
			pageLoading: false,
			tableForm: {
				type: 'ALL'
			},
			tableForm2: { keywords: '' },
			columns1: [
				{
					// 交易类型
					title: this.$t('table.useraccount.tab2.cloums1.transaction_type'),
					dataIndex: 'gradeOne',
					align: 'center',
					width: 100,
					customRender: (v, o) => {
						let result = o.isTotal ? v : ''
						return result
					}
				},
				{
					// 二级分类
					title: this.$t('table.useraccount.tab2.cloums1.secondary'),
					dataIndex: 'gradeTwo',
					align: 'center',
					customRender: (v, o) => {
						let result = o.isTotal ? this.$t('table.total') : v
						return result
					}
				},
				{
					// 交易笔数
					title: this.$t('table.useraccount.tab2.cloums1.transactions_num'),
					dataIndex: 'transCount',
					align: 'center'
				},
				{
					// 收入笔数
					title: this.$t('table.useraccount.tab2.cloums1.income_num'),
					dataIndex: 'incomeCount',
					align: 'center',
					customRender: (v, o) => {
						let result = v
						if (o.transCount > 0) {
							result = result + '(' + ((v * 100) / o.transCount).toFixed(2) + '%)'
						}
						return result
					}
				},
				{
					// 支出笔数
					title: this.$t('table.useraccount.tab2.cloums1.expenditure_num'),
					dataIndex: 'expenseCount',
					align: 'center',
					customRender: (v, o) => {
						let result = v
						if (o.transCount > 0) {
							result = result + '(' + ((v * 100) / o.transCount).toFixed(2) + '%)'
						}
						return result
					}
				},
				{
					// 收入金额
					title: this.$t('table.useraccount.tab2.cloums1.income'),
					dataIndex: 'income',
					align: 'center'
				},
				{
					// 支出金额
					title: this.$t('table.useraccount.tab2.cloums1.expenditure'),
					dataIndex: 'expense',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('operate.operate'),
					align: 'center',
					scopedSlots: { customRender: 'action' },
					width: 100
				}
			],
			dataSource1: [],
			columns2: [
				{
					// 类型
					title: this.$t('table.useraccount.tab2.cloums1.type'),
					dataIndex: 'gradeTwo',
					align: 'center',
					width: 100
				},
				{
					// 交易笔数
					title: this.$t('table.useraccount.tab2.cloums1.transactions_num'),
					dataIndex: 'transCount',
					align: 'center'
				},
				{
					// 收入笔数
					title: this.$t('table.useraccount.tab2.cloums1.income_num'),
					dataIndex: 'incomeCount',
					align: 'center',
					customRender: (v, o) => {
						let result = v
						if (o.transCount > 0) {
							result = result + '(' + ((v * 100) / o.transCount).toFixed(2) + '%)'
						}
						return result
					}
				},
				{
					// 支出笔数
					title: this.$t('table.useraccount.tab2.cloums1.expenditure_num'),
					dataIndex: 'expenseCount',
					align: 'center',
					customRender: (v, o) => {
						let result = v
						if (o.transCount > 0) {
							result = result + '(' + ((v * 100) / o.transCount).toFixed(2) + '%)'
						}
						return result
					}
				},
				{
					// 收入金额
					title: this.$t('table.useraccount.tab2.cloums1.income'),
					dataIndex: 'income',
					align: 'center'
				},
				{
					// 支出金额
					title: this.$t('table.useraccount.tab2.cloums1.expenditure'),
					dataIndex: 'expense',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('operate.operate'),
					align: 'center',
					scopedSlots: { customRender: 'action' },
					width: 100
				}
			],
			dataSource2: [],
			columns3: [
				{
					// 关键词
					title: this.$t('table.useraccount.tab2.cloums1.keyword'),
					dataIndex: 'gradeTwo',
					align: 'center',
					width: 100
				},
				{
					// 交易笔数
					title: this.$t('table.useraccount.tab2.cloums1.transactions_num'),
					dataIndex: 'transCount',
					align: 'center'
				},
				{
					// 收入笔数
					title: this.$t('table.useraccount.tab2.cloums1.income_num'),
					dataIndex: 'incomeCount',
					align: 'center',
					customRender: (v, o) => {
						let result = v
						if (o.transCount > 0) {
							result = result + '(' + ((v * 100) / o.transCount).toFixed(2) + '%)'
						}
						return result
					}
				},
				{
					// 支出笔数
					title: this.$t('table.useraccount.tab2.cloums1.expenditure_num'),
					dataIndex: 'expenseCount',
					align: 'center',
					customRender: (v, o) => {
						let result = v
						if (o.transCount > 0) {
							result = result + '(' + ((v * 100) / o.transCount).toFixed(2) + '%)'
						}
						return result
					}
				},
				{
					// 收入金额
					title: this.$t('table.useraccount.tab2.cloums1.income'),
					dataIndex: 'income',
					align: 'center'
				},
				{
					// 支出金额
					title: this.$t('table.useraccount.tab2.cloums1.expenditure'),
					dataIndex: 'expense',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('operate.operate'),
					align: 'center',
					scopedSlots: { customRender: 'action' },
					width: 100
				}
			],
			dataSource3: [],
			modal: {
				show: false,
				title: this.$t('table.useraccount.tab1.title3'),
				cloums: [
					{
						// 交易时间
						title: this.$t('table.useraccount.tab1.cloums3.tranDate'),
						dataIndex: 'tranDate',
						align: 'center',
						width: 200,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 交易渠道
						title: this.$t('table.useraccount.tab1.cloums3.transChannel'),
						dataIndex: 'transChannel',
						align: 'center',
						width: 80,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 交易类型
						title: this.$t('table.useraccount.tab1.cloums3.transType'),
						dataIndex: 'transType',
						align: 'center',
						width: 80,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 详情
						title: this.$t('table.useraccount.tab1.cloums3.detail'),
						dataIndex: 'detail',
						align: 'center',
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 收入
						title: this.$t('table.useraccount.tab1.cloums3.income'),
						dataIndex: 'income',
						align: 'center',
						width: 80,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 支出
						title: this.$t('table.useraccount.tab1.cloums3.expense'),
						dataIndex: 'expense',
						align: 'center',
						width: 80,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 余额
						title: this.$t('table.useraccount.tab1.cloums3.balance'),
						dataIndex: 'balance',
						align: 'center',
						width: 80,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					}
				],
				dataSource: [],
				dataAll: [],
				pagination: {
					total: 0,
					// 展示页面总条数
					showTotal: (total, range) => `Total ${total} items`,
					hideOnSinglePage: false,
					current: 1,
					showSizeChanger: true,
					pageSize: 10,
					pageSizeOptions: ['10', '20', '50', '100'],
					onChange: (current, pageSize) => {},
					onShowSizeChange: (current, pageSize) => {}
				}
			}
		}
	},
	props: ['id', 'isFileResult'],
	computed: {
		...mapGetters(['bankCode'])
	},
	created() {
		this.$nextTick(() => {
			this.init()
		})
	},
	methods: {
		onDetail(obj) {},
		init() {
			JSON.stringify
			this.pageLoading = true
			// { id: this.id, type: this.tableForm.type }
			let fileName = []
			if (this.isFileResult) {
				if (this.tableForm.type == 'ALL') {
					this.id.map((item) => {
						fileName.push(item.file)
					})
				} else {
					this.id.map((item) => {
						if (item.institution === this.tableForm.type) {
							fileName.push(item.file)
						}
					})
				}
			}
			this.isFileResult
				? apiGetFlowDetailByFileNames(fileName)
						.then((res) => {
							this.pageLoading = false
							let keylist = []
							// 取出所以一类名
							res.keyType.map((item) => {
								keylist.push(item.gradeOne)
							})
							// 去重一类名
							keylist = [...new Set(keylist)]
							this.dataSource1 = []
							keylist.map((item1) => {
								// 数组第一个为合计的
								let arr = [
									{
										isTotal: true,
										gradeOne: item1,
										gradeTwo: 'all',
										expense: 0,
										expenseCount: 0,
										income: 0,
										incomeCount: 0,
										transCount: 0,
										details: []
									}
								]
								// 遍历keyType 如果有一类名的 就加入数组
								res.keyType.map((item2) => {
									item2.isTotal = false
									if (item2.gradeOne == item1) {
										arr[0].expense += item2.expense
										arr[0].expenseCount += item2.expenseCount
										arr[0].income += item2.income
										arr[0].incomeCount += item2.incomeCount
										arr[0].transCount += item2.transCount
										arr[0].details = [...arr[0].details, ...item2.details]
										arr.push(item2)
									}
								})
								this.dataSource1 = [...this.dataSource1, ...arr]
							})
							this.dataSource2 = [res.otherType]
						})
						.catch((err) => {
							this.pageLoading = false
						})
				: apiGetFlowDetail({ id: this.id, type: this.tableForm.type })
						.then((res) => {
							this.pageLoading = false
							let keylist = []
							// 取出所以一类名
							res.keyType.map((item) => {
								keylist.push(item.gradeOne)
							})
							// 去重一类名
							keylist = [...new Set(keylist)]
							this.dataSource1 = []
							keylist.map((item1) => {
								// 数组第一个为合计的
								let arr = [
									{
										isTotal: true,
										gradeOne: item1,
										gradeTwo: 'all',
										expense: 0,
										expenseCount: 0,
										income: 0,
										incomeCount: 0,
										transCount: 0,
										details: []
									}
								]
								// 遍历keyType 如果有一类名的 就加入数组
								res.keyType.map((item2) => {
									item2.isTotal = false
									if (item2.gradeOne == item1) {
										arr[0].expense += item2.expense
										arr[0].expenseCount += item2.expenseCount
										arr[0].income += item2.income
										arr[0].incomeCount += item2.incomeCount
										arr[0].transCount += item2.transCount
										arr[0].details = [...arr[0].details, ...item2.details]
										arr.push(item2)
									}
								})
								this.dataSource1 = [...this.dataSource1, ...arr]
							})
							this.dataSource2 = [res.otherType]
						})
						.catch((err) => {
							this.pageLoading = false
						})
		},
		SearchKey() {
			if (this.tableForm2.keywords == '') {
				this.$message.error(this.$t('toast.msg8'))
				return false
			}
			this.pageLoading = true
			this.isFileResult
				? apiGetManualDetailByFileNames({
						fileNames: this.id,
						keywords: this.tableForm2.keywords
				  })
						.then((res) => {
							this.pageLoading = false
							this.dataSource3 = [...res, ...this.dataSource3]
						})
						.catch((err) => {
							this.pageLoading = false
						})
				: apiGetManualDetail({
						id: this.id,
						type: this.tableForm.type,
						keywords: this.tableForm2.keywords
				  })
						.then((res) => {
							this.pageLoading = false
							this.dataSource3 = [...res, ...this.dataSource3]
						})
						.catch((err) => {
							this.pageLoading = false
						})
		},
		showData(list) {
			this.modal.show = true
			this.modal.dataAll = list
			this.modal.pagination.total = list.length
			this.modal.pagination.current = 1
			this.modal.pagination.pageSize = 10
			this.modal.dataSource = this.getListArr()
		},
		handleTableChange(pagination, filters, sorter) {
			let { current, pageSize } = pagination
			this.modal.pagination.current = current
			this.modal.pagination.pageSize = pageSize
			this.modal.dataSource = this.getListArr()
		},
		getListArr() {
			let current = this.modal.pagination.current,
				pageSize = this.modal.pagination.pageSize,
				listAll = this.modal.dataAll,
				dataSource = []
			dataSource = listAll.slice((current - 1) * pageSize, current * pageSize)
			return dataSource
		}
	}
}
</script>
<style scoped lang="less">
.ant-card-bordered {
	margin-bottom: 10px;
}
</style>
