<template>
	<div>
		<a-card :title="$t('table.useraccount.tab1.title1')">
			<a-table
				style="font-size: 12px"
				:columns="columns"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="dataSource"
				:pagination="false"
				bordered
			>
				<span slot="action" slot-scope="obj">
					<a-button @click="delFile(obj.fileId)" :disabled="obj.status != '0'">{{ $t('table.useraccount.file.cloums.del') }}</a-button>
				</span>
			</a-table>
		</a-card>
		<a-card :title="$t('table.useraccount.tab1.title2')" v-if="totalResults.length > 0">
			<a-table
				style="font-size: 12px"
				:columns="columns2"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="totalResults"
				:pagination="false"
				:scroll="{
					x: true
				}"
				bordered
			>
				<span slot="action" slot-scope="obj">
					<a @click="showData(obj.incorrectList)">{{ obj.incorrectBalance }}</a>
				</span>
			</a-table>
		</a-card>
		<a-card title="M-pesa" v-if="mpesalist.length > 0">
			<a-table
				style="font-size: 12px"
				:columns="columns3"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="mpesalist"
				:pagination="false"
				:scroll="{
					x: true
				}"
				bordered
			>
				<span slot="action" slot-scope="obj">
					<a @click="showData(obj.incorrectList)">{{ obj.incorrectBalance }}</a>
				</span>
			</a-table>
		</a-card>

		<a-card :title="item.bankName" v-for="item in bankResults" :key="item.bankName">
			<a-table
				style="font-size: 12px"
				:columns="columns2"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="item.flowList"
				:pagination="false"
				bordered
				:scroll="{
					x: true
				}"
			>
			</a-table>
		</a-card>

		<!-- 弹窗 -->
		<a-modal v-drag-modal v-model="modal.show" :title="modal.title" width="70%" :footer="null">
			<a-table
				style="font-size: 12px"
				:columns="modal.cloums"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="modal.dataSource"
				:pagination="modal.pagination"
				@change="handleTableChange"
				bordered
				:scroll="{
					x: true,
					y: 600
				}"
			>
			</a-table>
		</a-modal>
	</div>
</template>
<script>
export default {
	props: ['details'],
	data() {
		return {
			dataSource: [],
			columns: [
				{
					// 文件类型
					title: this.$t('table.useraccount.tab1.cloums1.fileUsedType'),
					dataIndex: 'bankType',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : o.fileUsedType
					}
				},
				{
					// 文件名
					title: this.$t('table.useraccount.tab1.cloums1.fileName'),
					dataIndex: 'fileName',
					align: 'center'
				},
				{
					// 姓名
					title: this.$t('table.useraccount.tab1.cloums1.name'),
					dataIndex: 'parsedUsername',
					align: 'center'
				},
				{
					// 手机号
					title: this.$t('table.useraccount.tab1.cloums1.mobile'),
					dataIndex: 'parsedPhonenum',
					align: 'center'
				},
				{
					// 邮箱
					title: this.$t('table.useraccount.tab1.cloums1.email'),
					dataIndex: 'parsedEmail',
					align: 'center'
				},
				{
					// 流水日期
					title: this.$t('table.useraccount.tab1.cloums1.date'),
					dataIndex: 'flowStartDate',
					align: 'center',
					customRender: (v, o) => {
						if (o.flowStartDate && o.flowEndDate) {
							return o.flowStartDate + ' - ' + o.flowEndDate
						} else if (o.flowStartDate) {
							return o.flowStartDate
						} else if (o.flowEndDate) {
							return o.flowEndDate
						}
					}
				}
			],
			columns2: [
				{
					// 月份
					title: this.$t('table.useraccount.tab1.cloums2.month'),
					dataIndex: 'month',
					align: 'center',
					width: 100,
					fixed: 'left',
					customRender: (v, o) => {
						let result = v == 'all' ? this.$t('table.total') : v
						return result
					}
				},
				{
					// 流水开始时间
					title: this.$t('table.useraccount.tab1.cloums2.start_time'),
					dataIndex: 'recodeStartDate',
					align: 'center'
				},
				{
					// 流水开始时间
					title: this.$t('table.useraccount.tab1.cloums2.end_time'),
					dataIndex: 'recodeEndDate',
					align: 'center'
				},
				{
					// 交易笔数
					title: this.$t('table.useraccount.tab1.cloums2.transactions_num'),
					dataIndex: 'recodeCount',
					align: 'center'
				},
				{
					// 收入笔数
					title: this.$t('table.useraccount.tab1.cloums2.income_num'),
					dataIndex: 'incomeCount',
					align: 'center',
					customRender: (v, o) => {
						if (parseInt(o.recodeCount) > 0) {
							let result = ((Number(v) * 100) / Number(o.recodeCount)).toFixed(2) + '%'
							return v + '(' + result + ')'
						} else {
							return v
						}
					}
				},
				{
					// 支出笔数
					title: this.$t('table.useraccount.tab1.cloums2.expenditure_num'),
					dataIndex: 'expenseCount',
					align: 'center',
					customRender: (v, o) => {
						if (parseInt(o.recodeCount) > 0) {
							let result = ((Number(v) * 100) / Number(o.recodeCount)).toFixed(2) + '%'
							return v + '(' + result + ')'
						} else {
							return v
						}
					}
				},
				{
					// 整体收入金额
					title: this.$t('table.useraccount.tab1.cloums2.income_all'),
					dataIndex: 'totalIncome',
					align: 'center'
				},
				{
					// 整体支出金额
					title: this.$t('table.useraccount.tab1.cloums2.expenditure_all'),
					dataIndex: 'totalExpense',
					align: 'center',
					width: 100
				},
				{
					// 实际收入金额
					title: this.$t('table.useraccount.tab1.cloums2.income_actual'),
					dataIndex: 'actualIncome',
					align: 'center',
					customRender: (v, o) => {
						if (o.totalIncome != 0) {
							let result = ((Number(v) * 100) / Number(o.totalIncome)).toFixed(2) + '%'
							return v + '(' + result + ')'
						} else {
							return v
						}
					}
				},
				{
					// 实际支出金额
					title: this.$t('table.useraccount.tab1.cloums2.expenditure_actual'),
					dataIndex: 'actualExpense',
					align: 'center',
					customRender: (v, o) => {
						if (o.totalExpense != 0) {
							let result = ((Number(v) * 100) / Number(o.totalExpense)).toFixed(2) + '%'
							return v + '(' + result + ')'
						} else {
							return v
						}
					}
				},
				{
					// 内部收入金额
					title: this.$t('table.useraccount.tab1.cloums2.income_internal'),
					dataIndex: 'innerIncome',
					align: 'center'
				},
				{
					// 内部支出金额
					title: this.$t('table.useraccount.tab1.cloums2.expenditure_internal'),
					dataIndex: 'innerExpense',
					align: 'center'
				},
				{
					// 日均余额
					title: this.$t('table.useraccount.tab1.cloums2.balance'),
					dataIndex: 'avgBalance',
					align: 'center'
				},
				{
					// 最大余额
					title: this.$t('table.useraccount.tab1.cloums2.max_balance'),
					dataIndex: 'maxBalance',
					align: 'center',
					width: 100
				}
				// {
				//   // 余额差
				//   title: this.$t("table.useraccount.tab1.cloums2.diff_balance"),
				//   align: "center",
				//   scopedSlots: { customRender: "action" },
				//   width: 100,
				//   fixed: "right"
				// }
			],
			columns3: [
				{
					// 月份
					title: this.$t('table.useraccount.tab1.cloums2.month'),
					dataIndex: 'month',
					align: 'center',
					width: 100,
					fixed: 'left',
					customRender: (v, o) => {
						let result = v == 'all' ? this.$t('table.total') : v
						return result
					}
				},
				{
					// 流水开始时间
					title: this.$t('table.useraccount.tab1.cloums2.start_time'),
					dataIndex: 'recodeStartDate',
					align: 'center'
				},
				{
					// 流水开始时间
					title: this.$t('table.useraccount.tab1.cloums2.end_time'),
					dataIndex: 'recodeEndDate',
					align: 'center'
				},
				{
					// 交易笔数
					title: this.$t('table.useraccount.tab1.cloums2.transactions_num'),
					dataIndex: 'recodeCount',
					align: 'center'
				},
				{
					// 收入笔数
					title: this.$t('table.useraccount.tab1.cloums2.income_num'),
					dataIndex: 'incomeCount',
					align: 'center',
					customRender: (v, o) => {
						if (parseInt(o.recodeCount) > 0) {
							let result = ((Number(v) * 100) / Number(o.recodeCount)).toFixed(2) + '%'
							return v + '(' + result + ')'
						} else {
							return v
						}
					}
				},
				{
					// 支出笔数
					title: this.$t('table.useraccount.tab1.cloums2.expenditure_num'),
					dataIndex: 'expenseCount',
					align: 'center',
					customRender: (v, o) => {
						if (parseInt(o.recodeCount) > 0) {
							let result = ((Number(v) * 100) / Number(o.recodeCount)).toFixed(2) + '%'
							return v + '(' + result + ')'
						} else {
							return v
						}
					}
				},
				{
					// 整体收入金额
					title: this.$t('table.useraccount.tab1.cloums2.income_all'),
					dataIndex: 'totalIncome',
					align: 'center'
				},
				{
					// 整体支出金额
					title: this.$t('table.useraccount.tab1.cloums2.expenditure_all'),
					dataIndex: 'totalExpense',
					align: 'center',
					width: 100
				},
				{
					// 实际收入金额
					title: this.$t('table.useraccount.tab1.cloums2.income_actual'),
					dataIndex: 'actualIncome',
					align: 'center',
					customRender: (v, o) => {
						if (o.totalIncome != 0) {
							let result = ((Number(v) * 100) / Number(o.totalIncome)).toFixed(2) + '%'
							return v + '(' + result + ')'
						} else {
							return v
						}
					}
				},
				{
					// 实际支出金额
					title: this.$t('table.useraccount.tab1.cloums2.expenditure_actual'),
					dataIndex: 'actualExpense',
					align: 'center',
					customRender: (v, o) => {
						if (o.totalExpense != 0) {
							let result = ((Number(v) * 100) / Number(o.totalExpense)).toFixed(2) + '%'
							return v + '(' + result + ')'
						} else {
							return v
						}
					}
				},
				{
					// 内部收入金额
					title: this.$t('table.useraccount.tab1.cloums2.income_internal'),
					dataIndex: 'innerIncome',
					align: 'center'
				},
				{
					// 内部支出金额
					title: this.$t('table.useraccount.tab1.cloums2.expenditure_internal'),
					dataIndex: 'innerExpense',
					align: 'center'
				},
				{
					// 日均余额
					title: this.$t('table.useraccount.tab1.cloums2.balance'),
					dataIndex: 'avgBalance',
					align: 'center'
				},
				{
					// 最大余额
					title: this.$t('table.useraccount.tab1.cloums2.max_balance'),
					dataIndex: 'maxBalance',
					align: 'center',
					width: 100
				},
				{
					// 余额差
					title: this.$t('table.useraccount.tab1.cloums2.diff_balance'),
					align: 'center',
					scopedSlots: { customRender: 'action' },
					width: 100,
					fixed: 'right'
				}
			],
			mpesalist: [],
			totalResults: [],
			bankResults: [],
			modal: {
				show: false,
				title: this.$t('table.useraccount.tab1.title3'),
				cloums: [
					{
						// 交易时间
						title: this.$t('table.useraccount.tab1.cloums3.tranDate'),
						dataIndex: 'tranDate',
						align: 'center',
						width: 200,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 交易渠道
						title: this.$t('table.useraccount.tab1.cloums3.transChannel'),
						dataIndex: 'transChannel',
						align: 'center',
						width: 80,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 交易类型
						title: this.$t('table.useraccount.tab1.cloums3.transType'),
						dataIndex: 'transType',
						align: 'center',
						width: 80,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 详情
						title: this.$t('table.useraccount.tab1.cloums3.detail'),
						dataIndex: 'detail',
						align: 'center',
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 收入
						title: this.$t('table.useraccount.tab1.cloums3.income'),
						dataIndex: 'income',
						align: 'center',
						width: 80,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 支出
						title: this.$t('table.useraccount.tab1.cloums3.expense'),
						dataIndex: 'expense',
						align: 'center',
						width: 80,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					},
					{
						// 余额
						title: this.$t('table.useraccount.tab1.cloums3.balance'),
						dataIndex: 'balance',
						align: 'center',
						width: 80,
						customRender: (v, o) => {
							let result = v ? v : '-'
							return result
						}
					}
				],
				dataSource: [],
				dataAll: [],
				pagination: {
					total: 0,
					// 展示页面总条数
					showTotal: (total, range) => `Total ${total} items`,
					hideOnSinglePage: false,
					current: 1,
					showSizeChanger: true,
					pageSize: 10,
					pageSizeOptions: ['10', '20', '50', '100'],
					onChange: (current, pageSize) => {},
					onShowSizeChange: (current, pageSize) => {}
				}
			}
		}
	},
	created() {
		this.$nextTick(() => {
			this.dataSource = this.details.fileList
			this.mpesalist = this.checkData(this.details.mpesaResults)
			this.totalResults = this.checkData(this.details.totalResults)
			if (this.details.bankResults) {
				this.details.bankResults.map((item) => {
					item.flowList = this.checkData(item.flowList)
				})
				this.bankResults = this.details.bankResults
			}
		})
	},
	methods: {
		checkData(data) {
			if (data && data.length > 0) {
				let obj = {
					month: 'all',
					recodeStartDate: data.at(0).recodeStartDate,
					recodeEndDate: data.at(-1).recodeEndDate,
					recodeCount: 0,
					incomeCount: 0,

					expenseCount: 0,
					totalIncome: 0,

					totalExpense: 0,
					actualIncome: 0,

					actualExpense: 0,
					innerIncome: 0,

					innerExpense: 0,
					avgBalance: 0,

					maxBalance: 0,
					incorrectBalance: 0,
					incorrectList: []
				}
				data.map((item) => {
					obj.recodeCount += Number(item.recodeCount)
					obj.incomeCount += Number(item.incomeCount)

					obj.expenseCount += Number(item.expenseCount)
					obj.totalIncome += Number(item.totalIncome)

					obj.totalExpense += Number(item.totalExpense)
					obj.actualIncome += Number(item.actualIncome)

					obj.actualExpense += Number(item.actualExpense)
					obj.innerIncome += Number(item.innerIncome)

					obj.innerExpense += Number(item.innerExpense)
					obj.avgBalance += Number(item.avgBalance)

					obj.maxBalance += Number(item.maxBalance)
					obj.incorrectBalance += Number(item.incorrectBalance)
					if (item.incorrectList) {
						obj.incorrectList = [...obj.incorrectList, ...item.incorrectList]
					}
				})
				obj.recodeCount = obj.recodeCount.toFixed(0)
				obj.incomeCount = obj.incomeCount.toFixed(0)

				obj.expenseCount = obj.expenseCount.toFixed(0)
				obj.totalIncome = obj.totalIncome.toFixed(2)

				obj.totalExpense = obj.totalExpense.toFixed(2)
				obj.actualIncome = obj.actualIncome.toFixed(2)

				obj.actualExpense = obj.actualExpense.toFixed(2)
				obj.innerIncome = obj.innerIncome.toFixed(2)

				obj.innerExpense = obj.innerExpense.toFixed(2)
				obj.avgBalance = (obj.avgBalance / data.length).toFixed(2)

				obj.maxBalance = (obj.maxBalance / data.length).toFixed(2)
				obj.incorrectBalance = obj.incorrectBalance.toFixed(2)
				data = [obj, ...data]
				return data
			} else {
				return []
			}
		},
		showData(list) {
			this.modal.show = true
			this.modal.dataAll = list
			this.modal.pagination.total = list.length
			this.modal.pagination.current = 1
			this.modal.pagination.pageSize = 10
			this.modal.dataSource = this.getListArr()
		},
		handleTableChange(pagination, filters, sorter) {
			let { current, pageSize } = pagination
			this.modal.pagination.current = current
			this.modal.pagination.pageSize = pageSize
			this.modal.dataSource = this.getListArr()
		},
		getListArr() {
			let current = this.modal.pagination.current,
				pageSize = this.modal.pagination.pageSize,
				listAll = this.modal.dataAll,
				dataSource = []
			dataSource = listAll.slice((current - 1) * pageSize, current * pageSize)
			return dataSource
		}
	}
}
</script>
<style scoped lang="less">
.ant-card-bordered {
	margin-bottom: 10px;
}
</style>
