<template>
	<div>
		<a-card>
			<a-tabs default-active-key="1">
				<a-tab-pane key="1" :tab="$t('table.useraccount.flowTab.tab1.title')">
					<a-table
						style="font-size: 12px"
						:columns="incomeColumns"
						size="small"
						:rowKey="(record, index) => index"
						:dataSource="incomeData"
						:pagination="false"
						bordered
					>
					</a-table>
				</a-tab-pane>
				<a-tab-pane key="2" :tab="$t('table.useraccount.flowTab.tab2.title')">
					<a-table
						style="font-size: 12px"
						:columns="expensesColumns"
						size="small"
						:rowKey="(record, index) => index"
						:dataSource="expensesData"
						:pagination="false"
						bordered
					>
					</a-table>
				</a-tab-pane>
				<a-tab-pane key="3" :tab="$t('table.useraccount.flowTab.tab3.title')">
					<a-table
						style="font-size: 12px"
						:columns="balanceColumns"
						size="small"
						:rowKey="(record, index) => index"
						:dataSource="balanceData"
						:pagination="false"
						bordered
					>
					</a-table>
				</a-tab-pane>
				<a-tab-pane key="4" :tab="$t('table.useraccount.flowTab.tab4.title')">
					<a-table
						style="font-size: 12px"
						:columns="loansRepaymentColumns"
						size="small"
						:rowKey="(record, index) => index"
						:dataSource="loansRepaymentData"
						:pagination="false"
						bordered
					>
					</a-table>
				</a-tab-pane>
			</a-tabs>
		</a-card>
	</div>
</template>
<script>
import {
	apiFlowIncomeDetail,
	apiFlowExpenseDetail,
	apiFlowBalanceDetail,
	apiFlowLoanAndRepaymentDetail,
	apiFlowIncomeDetailByFileNames,
	apiFlowExpenseDetailByFileNames,
	apiFlowBalanceDetailByFileNames,
	apiFlowLoanAndRepaymentDetailByFileNames
} from '@/api/client'
export default {
	props: ['reqParam', 'isFileResult'],
	data() {
		return {
			incomeData: [],
			expensesData: [],
			balanceData: [],
			loansRepaymentData: []
		}
	},
	computed: {
		incomeColumns() {
			return [
				{
					title: this.$t('table.useraccount.flowTab.cloums.category'),
					dataIndex: 'category',
					align: 'center',
					customRender: (text, row, index) => {
						if (index === 0) {
							return {
								children: this.$t('table.useraccount.flowTab.tab1.regInc')
							}
						}
						if (index === 1) {
							return {
								children: this.$t('table.useraccount.flowTab.tab1.variInc'),
								attrs: {
									rowSpan: 3
								}
							}
						}
						if (index === 2 || index === 3) {
							return {
								attrs: {
									rowSpan: 0
								}
							}
						}
						if (index === 4) {
							return {
								children: this.$t('table.useraccount.flowTab.tab1.nonInc'),
								attrs: {
									rowSpan: 4
								}
							}
						}
						if (index === 5 || index === 6 || index === 7) {
							return {
								attrs: {
									rowSpan: 0
								}
							}
						}
						if (index === 8) {
							return {
								children: this.$t('table.useraccount.flowTab.tab1.others')
							}
						}
						if (index === 9) {
							return {
								children: this.$t('table.useraccount.flowTab.tab1.totNonInc')
							}
						}
						if (index === 10) {
							return {
								children: this.$t('table.useraccount.flowTab.cloums.total')
							}
						}
					}
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.details'),
					dataIndex: 'detailsName',
					align: 'center',
					customRender: (v) => {
						return this.$t(`table.useraccount.flowTab.tab1.${v}`)
					}
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.total'),
					dataIndex: 'resultTotal',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.average'),
					dataIndex: 'resultAvg',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.one'),
					dataIndex: 'month1',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.two'),
					dataIndex: 'month2',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.three'),
					dataIndex: 'month3',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.four'),
					dataIndex: 'month4',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.five'),
					dataIndex: 'month5',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.six'),
					dataIndex: 'month6',
					align: 'center'
				}
			]
		},
		expensesColumns() {
			return [
				{
					title: this.$t('table.useraccount.flowTab.cloums.category'),
					dataIndex: 'category',
					align: 'center',
					customRender: (text, row, index) => {
						if (index === 0) {
							return {
								children: this.$t('table.useraccount.flowTab.tab2.dailyExp')
							}
						}
						if (index === 1) {
							return {
								children: this.$t('table.useraccount.flowTab.tab2.othExp'),
								attrs: {
									rowSpan: 2
								}
							}
						}
						if (index === 2) {
							return {
								attrs: {
									rowSpan: 0
								}
							}
						}
						if (index === 3) {
							return {
								children: this.$t('table.useraccount.flowTab.tab2.extExp'),
								attrs: {
									rowSpan: 4
								}
							}
						}
						if (index === 4 || index === 5 || index === 6) {
							return {
								attrs: {
									rowSpan: 0
								}
							}
						}
						if (index === 7) {
							return {
								children: this.$t('table.useraccount.flowTab.tab2.tExp')
							}
						}
						if (index === 8) {
							return {
								children: this.$t('table.useraccount.flowTab.cloums.total')
							}
						}
					}
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.details'),
					dataIndex: 'detailsName',
					align: 'center',
					customRender: (v) => {
						return this.$t(`table.useraccount.flowTab.tab2.${v}`)
					}
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.total'),
					dataIndex: 'resultTotal',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.average'),
					dataIndex: 'resultAvg',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.one'),
					dataIndex: 'month1',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.two'),
					dataIndex: 'month2',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.three'),
					dataIndex: 'month3',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.four'),
					dataIndex: 'month4',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.five'),
					dataIndex: 'month5',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.six'),
					dataIndex: 'month6',
					align: 'center'
				}
			]
		},
		balanceColumns() {
			return [
				{
					title: this.$t('table.useraccount.flowTab.cloums.category'),
					dataIndex: 'detailsName',
					align: 'center',
					customRender: (v) => {
						return this.$t(`table.useraccount.flowTab.tab3.${v}`)
					}
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.average'),
					dataIndex: 'resultAvg',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.one'),
					dataIndex: 'month1',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.two'),
					dataIndex: 'month2',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.three'),
					dataIndex: 'month3',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.four'),
					dataIndex: 'month4',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.five'),
					dataIndex: 'month5',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.six'),
					dataIndex: 'month6',
					align: 'center'
				}
			]
		},
		loansRepaymentColumns() {
			return [
				{
					title: this.$t('table.useraccount.flowTab.cloums.category'),
					dataIndex: 'detailsName',
					align: 'center',
					customRender: (v) => {
						return this.$t(`table.useraccount.flowTab.tab4.${v}`)
					}
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.total'),
					dataIndex: 'resultTotal',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.average'),
					dataIndex: 'resultAvg',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.one'),
					dataIndex: 'month1',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.two'),
					dataIndex: 'month2',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.three'),
					dataIndex: 'month3',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.four'),
					dataIndex: 'month4',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.five'),
					dataIndex: 'month5',
					align: 'center'
				},
				{
					title: this.$t('table.useraccount.flowTab.cloums.six'),
					dataIndex: 'month6',
					align: 'center'
				}
			]
		}
	},
	created() {
		this.$nextTick(() => {
			this.isFileResult ? this.getFlowResult() : this.getFlowDetail()
		})
	},
	methods: {
		getFlowDetail() {
			Promise.all([
				apiFlowIncomeDetail(this.reqParam),
				apiFlowExpenseDetail(this.reqParam),
				apiFlowBalanceDetail(this.reqParam),
				apiFlowLoanAndRepaymentDetail(this.reqParam)
			]).then((res) => {
				let dataList = ['incomeData', 'expensesData', 'balanceData', 'loansRepaymentData']
				res.forEach((element, idx) => {
					if (element) {
						let index = 0
						for (let col in element.month1) {
							this[dataList[idx]].push({})
							for (let key in element) {
								this[dataList[idx]][index][key] = element[key][col]
								this[dataList[idx]][index]['detailsName'] = col
							}
							index++
						}
					}
				})
			})
		},
		getFlowResult() {
			Promise.all([
				apiFlowIncomeDetailByFileNames(this.reqParam),
				apiFlowExpenseDetailByFileNames(this.reqParam),
				apiFlowBalanceDetailByFileNames(this.reqParam),
				apiFlowLoanAndRepaymentDetailByFileNames(this.reqParam)
			]).then((res) => {
				let dataList = ['incomeData', 'expensesData', 'balanceData', 'loansRepaymentData']
				res.forEach((element, idx) => {
					if (element) {
						let index = 0
						for (let col in element.month1) {
							this[dataList[idx]].push({})
							for (let key in element) {
								this[dataList[idx]][index][key] = element[key][col]
								this[dataList[idx]][index]['detailsName'] = col
							}
							index++
						}
					}
				})
			})
		}
	}
}
</script>
<style scoped lang="less">
.ant-card-bordered {
	margin-bottom: 10px;
}
</style>
